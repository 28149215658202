import { CalendarFilled, CheckCircleOutlined } from "@ant-design/icons";
import { Button, Dropdown, Tag } from "antd";
import React from "react";

export const YearFilter = ({
	activeYear,
	years,
	filterBy,
	filteredBy,
	resettable = false,
	...props
}) => {
	const onClick = ({ key }) => {
		filterBy(key);
	};

	const items = [
		...years.map((year) => {
			return {
				key: year._id,
				label: (
					<div
						onClick={() => filterBy(year._id)}
						style={{ display: "flex", justifyContent: "space-between" }}>
						<div>
							{year.name}{" "}
							{year._id === activeYear._id ? (
								<Tag style={{ marginLeft: 4 }} color="green">
									Aktif
								</Tag>
							) : (
								<Tag style={{ marginLeft: 4, visibility: "hidden" }} color="green">
									Aktif
								</Tag>
							)}
						</div>
						{year._id === filteredBy.key && <CheckCircleOutlined />}
					</div>
				),
			};
		}),
	];

	return (
		<div {...props}>
			<Dropdown
				className="filter"
				trigger={["click"]}
				menu={{
					items: items,
					onClick: onClick,
				}}>
				<Button
					type={filteredBy.value !== "" ? "primary" : "default"}
					icon={<CalendarFilled />}
					className="ant-dropdown-link btn-filter">
					<span className="btn-filter-text">
						{filteredBy.value !== "" ? `: ${filteredBy.value}` : "Tidak Ada Yg Dipilih"}
					</span>
				</Button>
			</Dropdown>
		</div>
	);
};

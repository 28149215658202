import { Button, Descriptions, Form, message, Modal, notification, Popconfirm, Select } from "antd";
import { useEffect, useState } from "react";
import { requestGetClassroomList, requestMoveStudent } from "../../../../services/admin.service";

function ClassroomStudentMovingFormModal({ isVisible, classroom, student, onSuccess, onClose }) {
	const [form] = Form.useForm();
	const classroomId = Form.useWatch("classroomId", form);

	const [classrooms, setClassrooms] = useState([]);
	const [isLoading, setIsLoading] = useState(false);

	useEffect(() => {
		if (isVisible && student) {
			fetchClassrooms();
		}
	}, [isVisible, student]);

	const fetchClassrooms = () => {
		setIsLoading(true);
		requestGetClassroomList({
			page: 1,
			limit: 1000,
			filter: "year:current",
		})
			.then((response) => {
				setClassrooms(response.data.data);
			})
			.catch((error) => {
				notification.error({
					message: "Kesalahan!",
					description:
						error.response && error.response.data ? error.response.data.message : error.message,
				});
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	const handleSuccess = () => {
		form.resetFields();
		onSuccess();
	};

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				const payload = {
					studentId: student._id,
					classroomId: values.classroomId,
				};

				setIsLoading(true);
				requestMoveStudent(classroom._id, payload)
					.then((response) => {
						message.success(response.data.message);
						handleSuccess();
					})
					.catch((error) => {
						notification.error({
							message: "Kesalahan!",
							description:
								error.response && error.response.data ? error.response.data.message : error.message,
						});
					})
					.finally(() => {
						setIsLoading(false);
					});
			})
			.catch((error) => {
				notification.error({
					message: "Kesalahan!",
					description: "Silahkan lengkapi form dengan benar",
				});
			});
	};

	return (
		<Modal
			maskClosable={false}
			width={512}
			centered
			style={{
				top: 32,
			}}
			title={`Pindah ${student ? student.name : "Siswa"} ke Kelas Lain`}
			open={isVisible}
			cancelText="Batal"
			onCancel={handleClose}
			footer={[
				<Button key={1} onClick={handleClose}>
					Batal
				</Button>,
				<Popconfirm
					key={2}
					onConfirm={handleSubmit}
					title="Yakin akan menyimpan data laporan harian ini?"
					okText="Ya"
					cancelText="Tidak">
					<Button loading={isLoading} type="primary">
						{"Simpan"}
					</Button>
				</Popconfirm>,
			]}>
			<Form layout="vertical" form={form} requiredMark="optional">
				<Form.Item
					style={{ marginTop: 0, marginBottom: 0 }}
					name="classroomId"
					label="Kelas Tujuan"
					required
					rules={[{ required: true, message: "Pilih kelas" }]}
					tooltip="Pilih kelas">
					<Select
						loading={isLoading}
						style={{ width: "100%" }}
						placeholder="Pilih kelas"
						options={classrooms
							.filter((item) => item?._id !== classroom?._id)
							.map((classroom) => ({
								label: classroom.name,
								value: classroom._id,
							}))}
					/>
				</Form.Item>
				<Descriptions column={1} bordered size="small" style={{ marginTop: 16 }}>
					<Descriptions.Item label="Nama">{student ? student.name : "-"}</Descriptions.Item>
					<Descriptions.Item label="NISN">{student ? student.number : "-"}</Descriptions.Item>
					<Descriptions.Item label="Kelas Asal">
						{classroom ? classroom.name : "-"}
					</Descriptions.Item>
					<Descriptions.Item label="Kelas Tujuan">
						{classroomId ? classrooms.find((item) => item._id === classroomId).name : "-"}
					</Descriptions.Item>
				</Descriptions>
			</Form>
		</Modal>
	);
}

export default ClassroomStudentMovingFormModal;

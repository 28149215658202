/* eslint-disable react-hooks/exhaustive-deps */
import {
	Form,
	Input,
	Upload,
	Select,
	InputNumber,
	message,
	notification,
	Button,
	Popconfirm,
} from "antd";
import React, { useEffect, useState } from "react";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { resizeImage } from "../../../utils/ImageResizer";
import { requestUpdateSchool } from "../../../services/admin.service";

const SchoolForm = ({ school }) => {
	const [isLoading, setLoading] = useState(false);
	const [logoUrl, setLogoUrl] = useState();
	const [logo, setLogo] = useState();

	const [form] = Form.useForm();

	useEffect(() => {
		if (school) {
			form.setFieldsValue({
				number: school.number,
				name: school.name,
				logo: school.logo,
				address: school.address,
				timezone: school.timezone,
				modules: school.modules ?? [],
				studentQuota: school.quotas?.find((quota) => quota.key === "student")?.value ?? 100,
			});
			setLogoUrl(school.logoFile?.url);
		}
	}, [school]);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				const formData = new FormData();

				if (logo) {
					formData.append("logo", logo);

					setLoading(true);
					requestUpdateSchool(formData)
						.then((response) => {
							message.success("Data sekolah berhasil diperbarui!");
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification.error({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification.error({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							setLoading(false);
						});
				} else {
					notification.error({
						message: "Kesalahan!",
						description: "Tidak ada yang perlu diubah!",
					});
				}
			})
			.catch((error) => {});
	};

	const beforeUpload = async (file) => {
		const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
		if (!isJpgOrPng) {
			message.error("Anda hanya dapat mengunggah file JPG/PNG");
		}
		// const isLt2M = file.size / 1024 / 1024 < 2;
		// if (!isLt2M) {
		// 	message.error("Image must smaller than 2MB!");
		// }

		const isLt300Kb = file.size / 1024 < 300;
		if (!isLt300Kb) {
			message.error("Gambar harus lebih kecil dari 300KB!");
		}

		const compressedFile = await resizeImage(file, 512, 512);

		setLogo(compressedFile);
		setLogoUrl(URL.createObjectURL(compressedFile));

		// const reader = new FileReader();
		// reader.readAsDataURL(file);
		// reader.onload = () => {
		// var options = {
		// 	maxSizeMB: 0.3,
		// 	maxWidthOrHeight: 1024,
		// 	useWebWorker: true,
		// };
		// setLogo(file);
		// setLogoUrl(reader.result);
		// imageCompression(file, options)
		// 	.then(function (compressedFile) {
		// 		console.log("compressedFile", compressedFile); // true
		// 		setLogoUrl(reader.result);
		// 	})
		// 	.catch(function (error) {
		// 		if (error.response && error.response.data) {
		// 			notification["error"]({
		// 				message: "Kesalahan!",
		// 				description: error.response.data.message,
		// 			});
		// 		} else {
		// 			notification["error"]({
		// 				message: "Kesalahan!",
		// 				description: error.message,
		// 			});
		// 		}
		// 	});
		// };

		// then upload `file` from the argument manually
		return false;
	};

	const uploadButton = (
		<div>
			{isLoading ? <LoadingOutlined /> : <PlusOutlined />}
			<div
				style={{
					marginTop: 8,
				}}>
				Upload
			</div>
		</div>
	);

	return (
		<>
			<Form form={form} layout="vertical" requiredMark="optional">
				<Form.Item
					label="Nomor Pokok Sekolah Nasional (NPSN)"
					style={{ marginTop: 0, marginBottom: 0 }}
					name="number"
					rules={[{ required: true, message: "" }]}>
					<Input disabled style={{ width: "100%" }} placeholder="Nomor" />
				</Form.Item>
				<Form.Item
					label="Nama Sekolah"
					style={{ marginTop: 12, marginBottom: 0 }}
					name="name"
					rules={[{ required: true, message: "Masukkan nama" }]}>
					<Input disabled style={{ width: "100%" }} placeholder="Nama" />
				</Form.Item>
				{/* logo with input image */}
				<Form.Item label="Logo Sekolah" style={{ marginTop: 12, marginBottom: 0 }} name="logo">
					<Upload
						name="avatar"
						listType="picture-card"
						className="avatar-uploader"
						showUploadList={false}
						beforeUpload={beforeUpload}>
						{logoUrl ? (
							<img
								src={logoUrl}
								alt="avatar"
								style={{
									width: "100%",
								}}
							/>
						) : (
							uploadButton
						)}
					</Upload>
				</Form.Item>
				{/* Address */}
				<Form.Item
					label="Alamat"
					style={{ marginTop: 12, marginBottom: 0 }}
					name="address"
					rules={[{ required: true, message: "Masukkan alamat" }]}>
					<Input.TextArea disabled style={{ width: "100%" }} rows={2} placeholder="Alamat" />
				</Form.Item>
				{/* Timezone */}
				<Form.Item
					label="Zona Waktu"
					style={{ marginTop: 12, marginBottom: 0 }}
					name="timezone"
					rules={[{ required: true, message: "Masukkan zona waktu" }]}>
					<Select disabled placeholder="Zona waktu">
						<Select.Option value="">Pilih zona waktu</Select.Option>
						<Select.Option value="Asia/Jakarta">Asia/Jakarta (UTC +7)</Select.Option>
						<Select.Option value="Asia/Makassar">Asia/Makassar (UTC +8)</Select.Option>
						<Select.Option value="Asia/Jayapura">Asia/Jayapura (UTC +9)</Select.Option>
					</Select>
				</Form.Item>
				{/* Multi select modules */}
				{/* <Form.Item
					label="Modul"
					style={{ marginTop: 12, marginBottom: 0 }}
					name="modules"
					rules={[{ required: true, message: "Pilih modul" }]}>
					<Select
						disabled
						mode="multiple"
						placeholder="Modul"
						onChange={(value) => {
							setSelectedModules(value);
						}}>
						{modules.map((module) => (
							<Select.Option key={module._id} value={module.code}>
								{module.name}
							</Select.Option>
						))}
					</Select>
				</Form.Item> */}
				{/* Student quota */}
				<Form.Item
					label="Kuota Siswa"
					initialValue={1}
					style={{ marginTop: 12, marginBottom: 0 }}
					name="studentQuota"
					rules={[{ required: true, message: "Masukkan kuota siswa" }]}>
					<InputNumber disabled min={100} style={{ width: "100%" }} placeholder="Kuota siswa" />
				</Form.Item>
			</Form>
			<Popconfirm
				title="Apakah Anda yakin ingin menyimpan perubahan?"
				onConfirm={handleSubmit}
				okText="Ya"
				cancelText="Tidak">
				<Button
					style={{
						marginTop: 24,
						width: "100%",
					}}
					disabled={isLoading || !logo}
					type="primary">
					Simpan
				</Button>
			</Popconfirm>
			{/* <Divider />
			<Descriptions
				column={1}
				bordered
				size="small"
				style={{
					marginTop: 12,
				}}>
				{modules
					.filter((module) => selectedModules.includes(module.code))
					.map((module) => (
						<Descriptions.Item label={module.name} key={module._id}>
							<span>
								{format(module.price.actual * studentQuota)} @{format(module.price.actual)}
							</span>
						</Descriptions.Item>
					))}
				<Descriptions.Item label="Total">
					<span>
						{format(
							modules
								.filter((module) => selectedModules.includes(module.code))
								.reduce((total, module) => total + module.price.actual, 0) * studentQuota
						)}
					</span>
				</Descriptions.Item>
			</Descriptions> */}
		</>
	);
};

export default SchoolForm;

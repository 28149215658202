/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	Form,
	Input,
	Modal,
	App,
	Popconfirm,
	Spin,
	Typography,
	Radio,
	Select,
	Space,
} from "antd";
import React, { useEffect } from "react";
import { requestAddUser, requestEditUser } from "../../../../services/admin.service";
import countryCodes from "country-codes-list";
import parsePhoneNumber from "libphonenumber-js";

const UserFormModal = ({ user, isVisible, isLoading, onLoading, onClose, onSuccess }) => {
	const { notification } = App.useApp();
	const [role, setRole] = React.useState(null);

	const [form] = Form.useForm();

	useEffect(() => {
		if (user) {
			let number = "";
			if (user.role === "TEACHER" || user.role === "ADMIN" || user.role === "MASTER") {
				number = user.teacher.number;
			} else if (user.role === "STUDENT" || user.role === "PARENT") {
				number = user.student.number;
			}

			form.setFieldsValue({
				role: user.role,
				number: number,
				name: user.name,
				email: user.email,
				countryCode: parsePhoneNumber(`+${user.phone}`).countryCallingCode,
				phone: user.phone.replace(`${parsePhoneNumber(`+${user.phone}`).countryCallingCode}`, ""),
			});
		} else {
			form.setFieldsValue({
				countryCode: "62",
			});
		}
	}, [user]);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);
				values.phone = `+${values.countryCode}${values.phone}`;
				if (user) {
					requestEditUser(user._id, values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				} else {
					requestAddUser(values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				}
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={512}
				title={user === null || user === undefined ? "Tambah Pengguna" : "Ubah Pengguna"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data pengguna?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{user ? "Simpan" : "Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Form.Item
							style={{ marginTop: 0, marginBottom: 0 }}
							name="role"
							label="Role"
							tooltip="Pilih role"
							rules={[
								{
									required: true,
									message: "Pilih role",
								},
							]}
							required>
							<Radio.Group
								disabled={user !== null && user !== undefined}
								onChange={(e) => {
									setRole(e.target.value);
								}}
								options={[
									{ label: "Guru", value: "TEACHER" },
									{ label: "Siswa", value: "STUDENT" },
									{ label: "Wali", value: "PARENT" },
								]}
								style={{ width: "100%" }}
								optionType="button"
								buttonStyle="solid"
							/>
						</Form.Item>
						<Form.Item
							label="Nomor ID"
							style={{ marginTop: 12, marginBottom: 0 }}
							name="number"
							rules={[{ required: true, message: "Masukkan nomor ID" }]}>
							<Input
								disabled={user !== null && user !== undefined}
								style={{ width: "100%" }}
								placeholder="Nomor ID"
							/>
						</Form.Item>
						<Form.Item
							label="Nama"
							style={{ marginTop: 12, marginBottom: 0 }}
							name="name"
							rules={[{ required: true, message: "Masukkan nama" }]}>
							<Input style={{ width: "100%" }} placeholder="Nama" />
						</Form.Item>
						{/* Note */}
						<Typography.Text
							type="secondary"
							style={{
								display: "block",
								marginBottom: "16px",
							}}>
							Nama yang Anda masukkan akan digunakan sebagai nama akun. Nama ini bisa berbeda dengan
							data yang tertera di kartu identitas.
						</Typography.Text>
						<Form.Item
							label="Email"
							style={{ marginTop: 12, marginBottom: 0 }}
							name="email"
							rules={[{ required: true, message: "Masukkan email" }]}>
							<Input style={{ width: "100%" }} placeholder="Email" />
						</Form.Item>
						<Form.Item label="No. Telepon" style={{ marginTop: 12, marginBottom: 0 }}>
							<Space.Compact
								style={{
									width: "100%",
								}}>
								<Form.Item
									name="countryCode"
									noStyle
									rules={[
										{
											required: true,
											message: "Pilih kode negara",
										},
									]}>
									<Select
										style={{ width: 80 }}
										filterOption={(input, option) =>
											option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
										}
										options={Object.keys(
											countryCodes.customList("countryCallingCode", "countryCode")
										).map((country) => {
											return {
												label: `+${country}`,
												value: `${country}`,
											};
										})}></Select>
								</Form.Item>
								<Form.Item
									name="phone"
									noStyle
									style={{
										flex: 1,
									}}
									rules={[{ required: true, message: "Masukkan nomor telepon" }]}>
									<Input
										onChange={(e) => {
											const value = e.target.value;
											if (value.length === 1 && value === "0") {
												e.target.value = "";
											}
										}}
										style={{ width: "100%" }}
										placeholder="No. Telepon"
									/>
								</Form.Item>
							</Space.Compact>
						</Form.Item>
						<Typography.Text
							type="secondary"
							style={{
								display: "block",
								marginBottom: "16px",
							}}>
							Masukkan nomor telepon tanpa angka 0 di depannya.
						</Typography.Text>
						<Form.Item
							label="Password"
							style={{ marginTop: 12, marginBottom: 0 }}
							name="password"
							rules={[{ required: !user, message: "Masukkan password" }]}>
							<Input.Password style={{ width: "100%" }} type="password" placeholder="Password" />
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default UserFormModal;

import React, { useEffect, useState } from "react";
import { Input } from "antd";

const Search = Input.Search;

const SearchInput = ({
	placeholder = "Cari sesuatu...",
	isLoading = false,
	defaultValue,
	onSearch,
	onChange,
	...props
}) => {
	const [value, setValue] = useState("");

	const handleSearch = (value) => {
		if (onSearch) {
			onSearch(value);
		}
	};

	const handleChange = (e) => {
		console.log(e.target.value);
		setValue(e.target.value);
		if (onChange) {
			onChange(e.target.value);
		}
	};

	useEffect(() => {
		setValue(defaultValue);
	}, [defaultValue]);

	return (
		<div {...props}>
			<Search
				value={value}
				loading={isLoading}
				className="search-input"
				placeholder={placeholder}
				onSearch={handleSearch}
				onChange={handleChange}
			/>
		</div>
	);
};

export default SearchInput;

import React, { useEffect, useState } from "react";
import { Button, Descriptions, Modal, notification, Space, Spin, Table } from "antd";
import { requestShowReportCard } from "../../../../services/admin.service";
import ReportCardDetailTableCell from "./ReportCardDetailTableCell";

const ReportCardDetailModal = ({ isVisible, reportCardId, onCancel }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [reportCard, setReportCard] = useState(null);
	const [dataSource, setDataSource] = useState([]);
	const [columns, setColumns] = useState([]);

	useEffect(() => {
		if (isVisible && reportCardId) {
			fetchReportCardDetail();
		} else {
			setReportCard(null);
			setDataSource([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible, reportCardId]);

	const fetchReportCardDetail = async () => {
		setIsLoading(true);

		requestShowReportCard(reportCardId)
			.then((response) => {
				const marks = response.data.data.marks;
				const assessments = response.data.data.marks[0].assessments;
				const refs = {};

				for (const mark of marks) {
					for (const assessment of assessments) {
						if (refs[`${mark._id}-${assessment._id}`] === undefined) {
							refs[`${mark._id}-${assessment._id}`] = React.createRef();
						}
					}
				}

				// map into data source
				const dataSource = response.data.data.marks.map((mark) => ({
					key: mark._id,
					...mark,
					...response.data.data.marks[0].assessments.reduce((acc, assessment) => {
						acc[`mark_${assessment._id}`] = (
							<ReportCardDetailTableCell
								inputRef={refs[`${mark._id}-${assessment._id}`]}
								markId={mark._id}
								assessmentId={assessment._id}
								score={mark.assessments.find((a) => a._id === assessment._id)?.score}
								onSaved={() => fetchReportCardDetail()}
								onMove={(direction) => {
									if (direction === "ArrowLeft") {
										const index = assessments.findIndex((a) => a._id === assessment._id);
										if (index > 0) {
											const prevAssessment = assessments[index - 1];
											const prevRef = refs[`${mark._id}-${prevAssessment._id}`];
											prevRef?.current.focus({
												cursor: "all",
											});
										}
									} else if (direction === "ArrowRight") {
										const index = assessments.findIndex((a) => a._id === assessment._id);
										if (index < assessments.length - 1) {
											const nextAssessment = assessments[index + 1];
											const nextRef = refs[`${mark._id}-${nextAssessment._id}`];
											nextRef?.current.focus({
												cursor: "all",
											});
										}
									} else if (direction === "ArrowUp") {
										const index = marks.findIndex((m) => m._id === mark._id);
										if (index > 0) {
											const prevMark = marks[index - 1];
											const prevRef = refs[`${prevMark._id}-${assessment._id}`];
											prevRef?.current.focus({
												cursor: "all",
											});
										}
									} else if (direction === "ArrowDown") {
										const index = marks.findIndex((m) => m._id === mark._id);
										if (index < marks.length - 1) {
											const nextMark = marks[index + 1];
											const nextRef = refs[`${nextMark._id}-${assessment._id}`];
											nextRef?.current.focus({
												cursor: "all",
											});
										}
									}
								}}
							/>
						);
						// acc[`mark_${assessment._id}`] = (
						// 	<Space>
						// 		<Typography.Text>
						// 			{mark.assessments.find((a) => a._id === assessment._id)?.score}
						// 		</Typography.Text>
						// 		<Button type="default" size="small" shape="circle" onClick={() => {}}>
						// 			<HiPencil />
						// 		</Button>
						// 	</Space>
						// );
						return acc;
					}, {}),
				}));

				const newColumns = [
					{
						title: "No",
						dataIndex: "no",
						key: "no",
						render: (text, record, index) => index + 1,
						width: 50,
						align: "center",
					},
					{
						title: "Mata Pelajaran",
						dataIndex: "subject",
						key: "subject",
						render: (text, record) => record.lesson.subject.name,
					},
					...response.data.data?.marks[0]?.assessments.map((assessment) => ({
						title: `${assessment.name} (${assessment.weight * 100}%)`,
						dataIndex: `mark_${assessment._id}`,
						key: `mark_${assessment._id}`,
						align: "center",
						width: 100,
					})),
					{
						title: "Total",
						dataIndex: "total",
						key: "total",
						align: "center",
						width: 80,
						render: (text, record) => {
							const total = record.assessments.reduce((acc, assessment) => {
								return acc + (record.assessments.find((a) => a._id === assessment._id)?.score || 0);
							}, 0);
							return total;
						},
					},
					{
						title: "Nilai Akhir",
						dataIndex: "final",
						key: "final",
						align: "center",
						width: 80,
						render: (text, record) => {
							const total = record.assessments.reduce((acc, assessment) => {
								return (
									acc +
									(record.assessments.find((a) => a._id === assessment._id)?.score *
										record.assessments.find((a) => a._id === assessment._id)?.weight || 0)
								);
							}, 0);
							return total;
						},
					},
				];

				setColumns(newColumns);
				setDataSource(dataSource);
				setReportCard(response.data.data);
			})
			.catch((error) => {
				notification.error({
					message: "Error",
					description: error?.response?.data?.message || "Something went wrong",
				});
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleClose = () => {
		setReportCard(null);
		onCancel();
	};

	return (
		<Modal
			maskClosable={false}
			width={"calc(100% - 64px)"}
			style={{
				top: 32,
			}}
			title={"Detail Buku Rapor"}
			open={isVisible}
			cancelText="Batal"
			onCancel={handleClose}
			footer={[
				<Button key={1} onClick={handleClose}>
					Tutup
				</Button>,
			]}>
			<Spin spinning={isLoading}>
				{reportCard && dataSource && dataSource.length > 0 && (
					<Space direction="vertical" size="small" style={{ width: "100%" }}>
						<Descriptions
							bordered
							column={2}
							size="small"
							style={{
								width: "100%",
							}}>
							<Descriptions.Item label="NISN">{reportCard?.student?.number}</Descriptions.Item>
							<Descriptions.Item label="Nama">{reportCard?.student?.name}</Descriptions.Item>
							<Descriptions.Item label="Jenis Kelamin">
								{reportCard?.student?.gender === "MALE" ? "Laki-laki" : "Perempuan"}
							</Descriptions.Item>
							<Descriptions.Item label="Kelas">{reportCard?.classroom?.name}</Descriptions.Item>
							<Descriptions.Item label="Thn Ajaran">{reportCard?.year?.name}</Descriptions.Item>
							<Descriptions.Item label="Semester">{reportCard?.semester?.name}</Descriptions.Item>
						</Descriptions>
						<Table
							size="small"
							bordered
							scroll={{ x: "max-content" }}
							columns={columns}
							pagination={false}
							dataSource={dataSource}
						/>
					</Space>
				)}
			</Spin>
		</Modal>
	);
};

export default ReportCardDetailModal;

import {
	Button,
	Form,
	Input,
	InputNumber,
	message,
	Modal,
	notification,
	Popconfirm,
	Slider,
	Space,
	Tag,
	Typography,
} from "antd";
import { useEffect, useState } from "react";
import { requestEditLesson } from "../../../../services/teacher.service";

const LessonCompetencyFormModal = ({
	open,
	lesson,
	competency,
	curriculum,
	onClose,
	onSuccess,
}) => {
	const [form] = Form.useForm();
	const [loading, setLoading] = useState(false);
	const [classifications, setClassifications] = useState([]);
	const threshold = Form.useWatch("threshold", form);

	useEffect(() => {
		if (open && curriculum) {
			setClassifications(curriculum.classifications);
		} else {
			setClassifications([]);
		}
	}, [open, curriculum]);

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	const handleSubmit = async () => {
		setLoading(true);

		form
			.validateFields()
			.then((values) => {
				const payload = {
					competencies: [
						...(lesson.competencies ?? []),
						{
							description: values.description,
							threshold: values.threshold,
						},
					],
				};
				requestEditLesson(lesson._id, payload)
					.then(() => {
						message.success("Kompetensi berhasil disimpan");
						form.resetFields();
						onSuccess();
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification.error({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						} else {
							notification.error({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch((error) => {
				setLoading(false);
			});
	};

	const getGradientColor = (value) => {
		const startColor = [255, 0, 0];
		const endColor = [0, 255, 0];
		const percentage = value / 100;
		const midColor = startColor.map((start, i) => {
			const end = endColor[i];
			const delta = end - start;
			return (start + delta * percentage).toFixed(0);
		});
		return `rgba(${midColor.join(",")}, 0.25)`;
	};

	return (
		<Modal
			maskClosable={false}
			width={512}
			title={competency ? "Edit Kompetensi" : "Tambah Kompetensi"}
			open={open}
			cancelText="Batal"
			onCancel={handleClose}
			footer={[
				<Button key={1} onClick={handleClose} loading={loading}>
					Batal
				</Button>,
				<Popconfirm
					key={2}
					onConfirm={handleSubmit}
					title="Yakin akan menyimpan kompetensi ini?"
					okText="Ya"
					cancelText="Tidak">
					<Button type="primary" loading={loading}>
						{competency ? "Simpan Perubahan" : "Tambah Kompetensi"}
					</Button>
				</Popconfirm>,
			]}>
			<Form layout="vertical" form={form} requiredMark="optional">
				{/* description and threshold */}
				<Form.Item
					style={{ marginTop: 0, marginBottom: 0 }}
					name="description"
					label="Deskripsi"
					rules={[
						{
							required: true,
							message: "Deskripsi tidak boleh kosong",
						},
					]}>
					<Input.TextArea placeholder="Deskripsi" autoSize={{ minRows: 3, maxRows: 6 }} />
				</Form.Item>
				<Form.Item
					style={{ marginTop: 12, marginBottom: 0 }}
					name="threshold"
					label="Treshold"
					rules={[
						{
							required: true,
							message: "Treshold tidak boleh kosong",
						},
					]}>
					<InputNumber min={0} max={100} placeholder="Treshold" style={{ width: "100%" }} />
				</Form.Item>
				<Slider
					value={threshold ?? 0}
					min={0}
					max={100}
					step={1}
					marks={classifications.reduce((acc, classification) => {
						acc[classification.threshold] = classification.label
							.split(" ")
							.map((word) => word[0])
							.join("");

						return acc;
					}, {})}
					style={{
						marginTop: 12,
					}}
					tooltip={{
						formatter: (value) => {
							const classification = classifications.find(
								(classification) => classification.threshold <= value
							);

							return classification ? `${classification.label} (${classification.threshold})` : "";
						},
					}}
					styles={{
						rail: {
							background: `linear-gradient(to right, ${getGradientColor(0)} 0%, ${getGradientColor(
								100
							)} 100%)`,
						},
						track: {
							background: "transparent",
						},
					}}
					onChange={(value) => form.setFieldsValue({ threshold: value })}
				/>
				<Space
					style={{
						display: "flex",
						flexWrap: "wrap",
						marginTop: 12,
					}}>
					{classifications.map((classification) => {
						return (
							<Tag
								key={classification._id}
								color={classification.threshold <= threshold ?? 0 ? "green" : "red"}
								onClick={() => form.setFieldsValue({ threshold: classification.threshold })}>
								{classification.label} ({">="}
								{classification.threshold})
							</Tag>
						);
					})}
					<Typography.Text type="secondary">
						*Kompetensi akan mencapai klasifikasi yang ditandai dengan warna hijau
					</Typography.Text>
				</Space>
			</Form>
		</Modal>
	);
};

export default LessonCompetencyFormModal;

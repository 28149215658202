/* eslint-disable react-hooks/exhaustive-deps */
import { App, Button, Form, Modal, Popconfirm, Select, Spin, Typography } from "antd";
import React, { useEffect, useState } from "react";
import {
	requestAssignTeacherToClassroom,
	requestAssignTeacherToLesson,
	requestGetTeacherList,
} from "../services/admin.service";

const TeacherAssignFormModal = ({
	classroom,
	lesson,
	isVisible,
	isLoading,
	onLoading,
	onClose,
	onSuccess,
}) => {
	const { notification } = App.useApp();

	const [form] = Form.useForm();

	const [teachers, setTeachers] = useState([]);

	useEffect(() => {
		fetchTeacherList();
	}, []);

	const fetchTeacherList = () => {
		onLoading(true);
		requestGetTeacherList({ page: 1, limit: 1000 })
			.then((response) => {
				setTeachers(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);
				if (classroom) {
					values.teacherIds = [values.teacherIds];
					requestAssignTeacherToClassroom(classroom._id, values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				} else {
					requestAssignTeacherToLesson(lesson._id, values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				}
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		onLoading(false);
		form.resetFields();
		onClose();
	};

	return (
		<>
			{(lesson || classroom) && (
				<Modal
					maskClosable={false}
					width={512}
					title={`Tugaskan Guru ke ${
						classroom ? `Kelas ${classroom.name}` : `Pelajaran ${lesson.subject.name}`
					}`}
					open={isVisible}
					cancelText="Batal"
					onCancel={handleClose}
					footer={[
						<Button key={1} onClick={handleClose}>
							Batal
						</Button>,
						<Popconfirm
							key={2}
							onConfirm={handleSubmit}
							title="Yakin akan menugaskan guru ini?"
							okText="Ya"
							cancelText="Tidak">
							<Button type="primary">{"Tugaskan"}</Button>
						</Popconfirm>,
					]}>
					<Spin spinning={isLoading}>
						<Form form={form} layout="vertical" requiredMark="optional">
							<Form.Item
								style={{ marginTop: 0, marginBottom: 0 }}
								name="teacherIds"
								label="Nama"
								required
								rules={[{ required: true, message: "Pilih guru!" }]}
								tooltip="Pilih guru">
								<Select
									mode={classroom ? null : "multiple"}
									placeholder="Pilih Guru"
									showSearch
									optionFilterProp="children"
									filterOption={(input, option) =>
										option.children.toLowerCase().includes(input.toLowerCase())
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}>
									{teachers.map((teacher) => (
										<Select.Option key={teacher._id} value={teacher._id}>
											{`[${teacher.number}] ${teacher.name}`}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
							<Form.Item
								style={{ marginTop: 12, marginBottom: 0 }}
								name="type"
								label="Tipe"
								required
								rules={[
									{ required: true, message: "Pilih tipe guru!" },
									{
										validator: (_, value) => {
											if (value === "MAIN") {
												const mainTeacher = teachers.find(
													(teacher) => teacher.type === "MAIN" && teacher.lessonId === lesson._id
												);
												if (mainTeacher) {
													return Promise.reject(`Guru utama untuk pelajaran ini sudah ada!`);
												}
											}
											return Promise.resolve();
										},
									},
								]}
								tooltip="Pilih tipe guru">
								<Select placeholder="Pilih Tipe Guru">
									{classroom ? (
										<>
											<Select.Option value="GUIDANCE">Wali Kelas</Select.Option>
											<Select.Option value="ASSISTANT">Asisten</Select.Option>
										</>
									) : (
										<>
											<Select.Option value="MAIN">Utama</Select.Option>
											<Select.Option value="ASSISTANT">Asisten</Select.Option>
											<Select.Option value="SUBTITUTE">Pengganti</Select.Option>
										</>
									)}
								</Select>
							</Form.Item>
							<div
								style={{
									marginTop: 12,
								}}>
								<Typography.Text
									type="secondary"
									style={{
										display: "block",
									}}>
									Tipe guru<strong> utama </strong>hanya boleh ada satu per pelajaran. Tipe guru
									<strong> wali kelas </strong>hanya boleh ada satu per kelas. Untuk tipe guru
									tersebut, bila ada guru yang sudah ditugaskan, maka guru tersebut akan digantikan
									oleh guru yang baru.
								</Typography.Text>
							</div>
						</Form>
					</Spin>
				</Modal>
			)}
		</>
	);
};

export default TeacherAssignFormModal;

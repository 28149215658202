import React, { useEffect, useState } from "react";
import {
	Button,
	Descriptions,
	Form,
	Input,
	message,
	Modal,
	notification,
	Popconfirm,
	Radio,
	Space,
	Spin,
	Table,
	Tooltip,
	Typography,
} from "antd";
import { requestShowReportCard, requestSignReportCard } from "../../../../services/teacher.service";
import {
	ArrowDownOutlined,
	ArrowRightOutlined,
	ArrowUpOutlined,
	CheckOutlined,
	Loading3QuartersOutlined,
} from "@ant-design/icons";

const ClassroomReportCardDetailModal = ({ isVisible, reportCardId, onCancel }) => {
	const [form] = Form.useForm();

	const [isLoading, setIsLoading] = useState(false);
	const [reportCard, setReportCard] = useState(null);
	const [dataSource, setDataSource] = useState([]);
	const [columns, setColumns] = useState([]);
	const [marks, setMarks] = useState([]);
	const [assessments, setAssessments] = useState([]);
	const [attendanceSummary, setAttendanceSummary] = useState(null);

	useEffect(() => {
		if (isVisible && reportCardId) {
			fetchReportCardDetail();
		} else {
			setReportCard(null);
			setDataSource([]);
			form.resetFields();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible, reportCardId]);

	useEffect(() => {
		if (reportCard) {
			if (reportCard.attendance) {
				setAttendanceSummary([
					{
						key: "absent",
						status: "absent",
						total: reportCard.attendance.absent,
					},
					{
						key: "present",
						status: "present",
						total: reportCard.attendance.present,
					},
					{
						key: "illness",
						status: "illness",
						total: reportCard.attendance.illness,
					},
					{
						key: "leave",
						status: "leave",
						total: reportCard.attendance.leave,
					},
					{
						key: "late",
						status: "late",
						total: reportCard.attendance.late,
					},
				]);
			} else {
				const attendances = reportCard.attendances;
				const summary = attendances.reduce(
					(acc, attendance) => {
						if (attendance.status === "EXCUSED" && attendance.permission.type === "ILLNESS") {
							attendance.status = "illness";
						} else if (attendance.status === "EXCUSED" && attendance.permission.type === "LEAVE") {
							attendance.status = "leave";
						}
						acc[attendance.status.toLowerCase()] += 1;
						return acc;
					},
					{
						absent: 0,
						present: 0,
						illness: 0,
						leave: 0,
						late: 0,
					}
				);

				setAttendanceSummary(
					Object.entries(summary).map(([status, total]) => ({
						key: status,
						status,
						total,
					}))
				);
			}
		} else {
			setAttendanceSummary(null);
		}
	}, [reportCard]);

	const fetchReportCardDetail = async () => {
		setIsLoading(true);

		requestShowReportCard(reportCardId)
			.then((response) => {
				const marks = response.data.data.marks;
				const assessments = response.data.data.marks[0]?.assessments ?? [];

				setMarks(marks);
				setAssessments(assessments);

				const refs = {};

				for (const mark of marks) {
					for (const assessment of assessments) {
						if (refs[`${mark._id}-${assessment._id}`] === undefined) {
							refs[`${mark._id}-${assessment._id}`] = React.createRef();
						}
					}
				}

				// map into data source
				const dataSource = response.data.data.marks.map((mark) => ({
					key: mark._id,
					...mark,
					...response.data.data.marks[0].assessments.reduce((acc, assessment) => {
						acc[`mark_${assessment._id}`] = mark.assessments.find(
							(a) => a._id === assessment._id
						)?.score;
						return acc;
					}, {}),
				}));

				const newColumns = [
					{
						title: "No",
						dataIndex: "no",
						key: "no",
						render: (text, record, index) => index + 1,
						width: 50,
						align: "center",
					},
					{
						title: "Mata Pelajaran",
						dataIndex: "subject",
						key: "subject",
						render: (text, record) => record.lesson.subject.name,
					},
					...response.data.data?.marks[0]?.assessments.map((assessment) => ({
						title: `${assessment.name} (${assessment.weight * 100}%)`,
						dataIndex: `mark_${assessment._id}`,
						key: `mark_${assessment._id}`,
						align: "center",
						width: 100,
					})),
					{
						title: "Total",
						dataIndex: "total",
						key: "total",
						align: "center",
						width: 80,
						render: (text, record) => {
							const score = record.score;
							if (!score)
								return (
									<Tooltip title={`Masih diproses oleh guru`} placement="top">
										<Space>
											<Loading3QuartersOutlined
												style={{
													color: "orange",
												}}
												spin
											/>
										</Space>
									</Tooltip>
								);
							const total = score.total;
							return total;
						},
					},
					{
						title: "Rata-rata",
						dataIndex: "average",
						key: "average",
						align: "center",
						width: 80,
						render: (text, record) => {
							const score = record.score;
							if (!score)
								return (
									<Tooltip title={`Masih diproses oleh guru`} placement="top">
										<Space>
											<Loading3QuartersOutlined
												style={{
													color: "orange",
												}}
												spin
											/>
										</Space>
									</Tooltip>
								);
							const average = score.average;
							return average;
						},
					},
					{
						title: "Nilai Akhir",
						dataIndex: "final",
						key: "final",
						align: "center",
						width: 80,
						render: (text, record) => {
							const score = record.score;
							if (!score)
								return (
									<Tooltip title={`Masih diproses oleh guru`} placement="top">
										<Space>
											<Loading3QuartersOutlined
												style={{
													color: "orange",
												}}
												spin
											/>
										</Space>
									</Tooltip>
								);
							const final = score.final;
							return final;
						},
					},
					// classification
					{
						title: "Klasifikasi",
						dataIndex: "classification",
						key: "classification",
						align: "center",
						width: 80,
						render: (text, record) => {
							const score = record.score;
							if (!score)
								return (
									<Tooltip title={`Masih diproses oleh guru`} placement="top">
										<Space>
											<Loading3QuartersOutlined
												style={{
													color: "orange",
												}}
												spin
											/>
										</Space>
									</Tooltip>
								);
							const classification = score.classification;
							return classification;
						},
					},
					// competency
					{
						title: "Keterangan",
						dataIndex: "competency",
						key: "competency",
						width: 240,
						render: (text, record) => {
							const score = record.score;
							if (!score)
								return (
									<Tooltip title={`Masih diproses oleh guru`} placement="top">
										<Space>
											<Loading3QuartersOutlined
												style={{
													color: "orange",
												}}
												spin
											/>
										</Space>
									</Tooltip>
								);
							const competency = score.competency;
							return competency;
						},
					},
				];

				// add data source below for total
				// dataSource.push({
				// 	key: "total",
				// 	lesson: {
				// 		subject: {
				// 			name: "Total",
				// 		},
				// 	},
				// 	...response.data.data.marks[0].assessments.reduce((acc, assessment) => {
				// 		acc[`mark_${assessment._id}`] = response.data.data.marks.reduce(
				// 			(sum, mark) =>
				// 				sum + (mark.assessments.find((a) => a._id === assessment._id)?.score || 0),
				// 			0
				// 		);
				// 		return acc;
				// 	}, {}),
				// 	score: {
				// 		total: response.data.data.marks.reduce((sum, mark) => sum + mark.score.total, 0),
				// 		average: response.data.data.marks.reduce((sum, mark) => sum + mark.score.average, 0),
				// 		final: response.data.data.marks.reduce((sum, mark) => sum + mark.score.final, 0),
				// 		classification: "",
				// 		competency: "",
				// 	},
				// });

				setColumns(newColumns);
				setDataSource(dataSource);
				setReportCard(response.data.data);
				form.setFieldsValue({
					note: response.data.data.note,
					action: response.data.data.action,
				});
			})
			.catch((error) => {
				notification.error({
					message: "Error",
					description: error?.response?.data?.message || "Something went wrong",
				});
			})
			.finally(() => {
				setIsLoading(false);
			});
	};

	const handleClose = () => {
		setReportCard(null);
		onCancel();
	};

	const handleSign = () => {
		form
			.validateFields()
			.then((values) => {
				setIsLoading(true);
				requestSignReportCard(reportCardId, values)
					.then((response) => {
						message.success("Rapor berhasil ditandatangani");
						fetchReportCardDetail();
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification.error({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						} else {
							notification.error({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						setIsLoading(false);
					});
			})
			.catch((error) => {
				console.log(error);
			});
	};

	return (
		<Modal
			maskClosable={false}
			width={"calc(100% - 64px)"}
			style={{
				top: 32,
			}}
			title={"Detail Buku Rapor"}
			open={isVisible}
			cancelText="Batal"
			onCancel={handleClose}
			footer={[
				<Button key={1} onClick={handleClose}>
					Tutup
				</Button>,
				<Popconfirm
					key={2}
					placement="topLeft"
					title="Apakah Anda yakin ingin menanda tangani rapor ini? Setelah ditandatangani, Anda tidak dapat mengubahnya lagi."
					onConfirm={handleSign}
					okText="Ya"
					cancelText="Tidak">
					<Button loading={isLoading} type="primary" disabled={reportCard?.signature?.guidance._id}>
						{reportCard?.signature?.guidance._id
							? "Rapor sudah ditanda tangani"
							: "Tanda tangani Rapor"}
					</Button>
				</Popconfirm>,
			]}>
			<Spin spinning={isLoading}>
				{reportCard && dataSource && dataSource.length > 0 && (
					<Space direction="vertical" size="large" style={{ width: "100%" }}>
						<Descriptions
							bordered
							column={2}
							size="small"
							style={{
								width: "100%",
							}}>
							<Descriptions.Item label="NISN">{reportCard?.student?.number}</Descriptions.Item>
							<Descriptions.Item label="Nama">{reportCard?.student?.name}</Descriptions.Item>
							<Descriptions.Item label="Jenis Kelamin">
								{reportCard?.student?.gender === "MALE" ? "Laki-laki" : "Perempuan"}
							</Descriptions.Item>
							<Descriptions.Item label="Kelas">{reportCard?.classroom?.name}</Descriptions.Item>
							<Descriptions.Item label="Thn Ajaran">{reportCard?.year?.name}</Descriptions.Item>
							<Descriptions.Item label="Semester">{reportCard?.semester?.name}</Descriptions.Item>
						</Descriptions>
						<Table
							size="small"
							bordered
							scroll={{ x: "max-content" }}
							columns={columns}
							pagination={false}
							dataSource={dataSource}
							summary={(pageData) => {
								let final = 0;

								pageData.forEach((record) => {
									// final += record.score.final;
									// check is valid number
									if (!isNaN(record.score.final)) {
										final += record.score.final;
									}
								});

								return (
									<>
										<Table.Summary.Row>
											<Table.Summary.Cell align="center" index={0} colSpan={assessments.length + 2}>
												<Typography.Text strong>Jumlah</Typography.Text>
											</Table.Summary.Cell>
											<Table.Summary.Cell colSpan={3} align="center">
												<Typography.Text strong>{final.toFixed(2)}</Typography.Text>
											</Table.Summary.Cell>
											<Table.Summary.Cell colSpan={2} align="center"></Table.Summary.Cell>
										</Table.Summary.Row>
										<Table.Summary.Row>
											<Table.Summary.Cell align="center" index={0} colSpan={assessments.length + 2}>
												<Typography.Text strong>Rata-rata</Typography.Text>
											</Table.Summary.Cell>
											<Table.Summary.Cell colSpan={3} align="center">
												<Typography.Text strong>
													{(final / marks.length).toFixed(2)}
												</Typography.Text>
											</Table.Summary.Cell>
											<Table.Summary.Cell colSpan={2} align="center"></Table.Summary.Cell>
										</Table.Summary.Row>
									</>
								);
							}}
						/>
						{/* Attendances Table */}
						<Table
							size="small"
							bordered
							scroll={{ x: "max-content" }}
							columns={[
								{
									title: "No",
									dataIndex: "no",
									key: "no",
									render: (text, record, index) => index + 1,
									width: 50,
									align: "center",
								},
								{
									title: "Status",
									dataIndex: "status",
									key: "status",
									render: (text, record) => {
										switch (record.status) {
											case "absent":
												return "Tanpa Keterangan";
											case "present":
												return "Hadir";
											case "illness":
												return "Izin Sakit";
											case "leave":
												return "Izin Pergi";
											case "late":
												return "Terlambat";
											default:
												return record.status;
										}
									},
								},
								{
									title: "Jumlah",
									dataIndex: "total",
									key: "total",
									render: (text, record) => `${record.total} hari`,
								},
							]}
							pagination={false}
							dataSource={attendanceSummary
								?.filter(
									(attendance) => attendance.status !== "present" && attendance.status !== "late"
								)
								.sort((a, b) => {
									const order = ["present", "late", "illness", "leave", "absent"];
									return order.indexOf(a.status) - order.indexOf(b.status);
								})}
						/>
						<Form form={form} layout="vertical" requiredMark="optional">
							<Form.Item
								style={{ marginTop: 0, marginBottom: 0 }}
								name="note"
								label="Catatan"
								rules={[
									{
										required: true,
										message: "Catatan tidak boleh kosong",
									},
								]}>
								<Input.TextArea
									disabled={reportCard?.signature?.guidance._id}
									placeholder="Catatan untuk wali murid"
									autoSize={{ minRows: 3, maxRows: 6 }}
								/>
							</Form.Item>
							<Form.Item
								style={{ marginTop: 12, marginBottom: 0 }}
								name="action"
								label="Aksi yang diambil"
								rules={[
									{
										required: true,
										message: "Aksi yang diambil tidak boleh kosong",
									},
								]}>
								<Radio.Group buttonStyle="solid" disabled={reportCard?.signature?.guidance._id}>
									<Radio.Button value="PROMOTE">
										<ArrowUpOutlined />
										Naik Kelas
									</Radio.Button>
									<Radio.Button value="RETAIN">
										<ArrowRightOutlined />
										Tetap di Kelas
									</Radio.Button>
									<Radio.Button value="DEMOTE">
										<ArrowDownOutlined />
										Turun Kelas
									</Radio.Button>
									<Radio.Button value="GRADUATE">
										<CheckOutlined />
										Lulus (Tidak ada kelas berikutnya)
									</Radio.Button>
								</Radio.Group>
							</Form.Item>
						</Form>
						{reportCard.signature?.guidance.image && reportCard.signature?.headmaster.image && (
							<Space direction="vertical" size="large" style={{ width: "100%" }}>
								<Descriptions
									bordered
									column={3}
									layout="vertical"
									size="small"
									style={{
										width: "100%",
									}}>
									<Descriptions.Item label="Kepala Sekolah">
										<Space direction="vertical">
											<img
												src={`data:image/png;base64,${reportCard.signature.headmaster.image}`}
												alt="Kepala Sekolah"
												style={{
													height: 96,
												}}
											/>
											<Typography.Text strong underline>
												{reportCard.signature.headmaster.name}
											</Typography.Text>
										</Space>
									</Descriptions.Item>
									<Descriptions.Item label="Wali Kelas">
										<Space direction="vertical">
											<img
												src={`data:image/png;base64,${reportCard.signature.guidance.image}`}
												alt="Wali Kelas"
												style={{
													height: 96,
												}}
											/>
											<Typography.Text strong underline>
												{reportCard.signature.guidance.name}
											</Typography.Text>
										</Space>
									</Descriptions.Item>
								</Descriptions>
							</Space>
						)}
					</Space>
				)}
			</Spin>
		</Modal>
	);
};

export default ClassroomReportCardDetailModal;

/* eslint-disable react-hooks/exhaustive-deps */
import { App, Button, Form, Input, InputNumber, Modal, Popconfirm, Select, Spin } from "antd";
import React, { useEffect } from "react";
import { addClassroom, editClassroom } from "../../../../services/admin.service";

const ClassroomFormModal = ({
	yearId,
	classroom,
	years,
	isVisible,
	isLoading,
	onLoading,
	onClose,
	onSuccess,
}) => {
	const { notification } = App.useApp();

	const [form] = Form.useForm();

	useEffect(() => {
		if (classroom !== null) {
			form.setFieldsValue({
				yearId: yearId,
				name: classroom.name,
				grade: classroom.grade,
			});
		} else {
			form.setFieldsValue({
				yearId: yearId,
				name: "",
				grade: null,
			});
		}
	}, [classroom, isVisible]);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);
				values.yearId = yearId;
				if (classroom) {
					values.id = classroom._id;
					editClassroom(values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				} else {
					addClassroom(values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				}
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={512}
				title={classroom === null || classroom === undefined ? "Tambah Kelas" : "Ubah Kelas"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data kelas?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{classroom ? "Simpan" : "Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Form.Item
							style={{ marginTop: 0, marginBottom: 0 }}
							name="yearId"
							label="Tahun Ajaran"
							required
							tooltip="Pilih tahun ajaran">
							<Select style={{ width: "100%" }} disabled>
								{years.map((year) => (
									<Select.Option key={year._id} value={year._id}>
										{year.name}
									</Select.Option>
								))}
							</Select>
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="grade"
							label="Tingkat"
							required
							tooltip="Masukkan tingkatan kelas">
							<InputNumber style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="name"
							label="Nama"
							required
							tooltip="Masukkan nama kelas">
							<Input placeholder="XI IPA 1" style={{ width: "100%" }} />
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default ClassroomFormModal;

import { Progress, Space, Typography } from "antd";

const SchoolQuotaProgressBar = ({ school }) => {
	return (
		<>
			<div
				style={{
					marginBottom: 10,
				}}>
				{school.quotas.map((quota) => {
					return (
						<Space key={quota.key} style={{ width: "100%" }} direction="vertical" align="center">
							<Progress
								type="circle"
								percent={(quota.used / quota.value) * 100 ?? 0}
								// stroke color red for more than 100%
								// stroke color yellow for more than 80%
								// stroke color green for less than 80%
								strokeColor={
									quota.used >= quota.value
										? "#f5222d"
										: quota.used >= quota.value * 0.8
										? "#faad14"
										: "#52c41a"
								}
								format={(percent) => (
									<Typography.Text strong>
										{((quota.used / quota.value) * 100).toFixed(0)}%
									</Typography.Text>
								)}
							/>
							<Typography.Text strong>{quota.name}</Typography.Text>
						</Space>
					);
				})}
			</div>
		</>
	);
};
export default SchoolQuotaProgressBar;

import { Button, Card, Form, InputNumber, notification, Popconfirm, Space } from "antd";
import { useState } from "react";
import { requestDepositPocket } from "../../../../services/admin.service";

const PocketDepositForm = ({ pocket, onSuccess, onClose }) => {
	const [form] = Form.useForm();

	const [isLoading, setLoading] = useState(false);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				setLoading(true);
				requestDepositPocket(pocket.id, values)
					.then((response) => {
						onSuccess();
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification.error({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						} else {
							notification.error({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						setLoading(false);
					});
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		onClose();
	};

	return (
		<Card title="Setor Saldo" size="small">
			<Form form={form} layout="vertical" requiredMark="optional">
				<Form.Item
					style={{ marginTop: 12, marginBottom: 12 }}
					required
					label="Jumlah"
					name="amount"
					rules={[
						{
							required: true,
							message: "Masukkan jumlah penarikan!",
						},
						{
							type: "number",
							message: "Masukkan angka!",
						},
						{
							validator: (rule, value) => {
								if (value < 1000) {
									return Promise.reject("Minimal 1000!");
								}

								return Promise.resolve();
							},
						},
					]}>
					<InputNumber
						style={{ width: "100%" }}
						min={0}
						step={1000}
						formatter={(value) => `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
						parser={(value) => value.replace(/Rp\s?|(,*)/g, "")}
					/>
				</Form.Item>
			</Form>
			<Space
				style={{
					display: "flex",
					justifyContent: "flex-end",
				}}>
				<Button type="default" onClick={handleClose} style={{ width: 100 }} disabled={isLoading}>
					Batal
				</Button>
				<Popconfirm
					title="Apakah Anda yakin?"
					onConfirm={handleSubmit}
					okText="Ya"
					cancelText="Tidak">
					<Button type="primary" style={{ width: 100 }} loading={isLoading}>
						Setor
					</Button>
				</Popconfirm>
			</Space>
		</Card>
	);
};

export default PocketDepositForm;

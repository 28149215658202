import React from "react";
import {
	Button,
	Card,
	Col,
	Descriptions,
	Modal,
	Row,
	Space,
	Spin,
	Steps,
	Table,
	Tag,
	notification,
} from "antd";
import dayjs from "dayjs";
import { requestGetInvoiceList, requestShowStudent } from "../../../../services/admin.service";

const invoiceColumns = [
	{
		title: "Nomor",
		dataIndex: "number",
		key: "number",
	},
	{
		title: "Tanggal",
		dataIndex: "issuedAt",
		key: "issuedAt",
		render: (text, record) => {
			return dayjs(text).format("DD MMMM YYYY");
		},
	},
	{
		title: "Status",
		dataIndex: "status",
		key: "status",
		render: (status) => {
			const readableStatus = {
				DRAFT: "DRAF",
				PARTIAL: "SEBAGIAN",
				SENT: "TERKIRIM",
				PAID: "TERBAYAR",
				VOID: "TIDAK SAH",
			};
			const color = {
				DRAFT: "error",
				PARTIAL: "warning",
				SENT: "processing",
				PAID: "success",
				VOID: "default",
			};
			return <Tag color={color[status]}>{readableStatus[status]}</Tag>;
		},
		filters: [
			{
				text: "Draf",
				value: "DRAFT",
			},
			{
				text: "Terkirim",
				value: "SENT",
			},
			{
				text: "Terbayar",
				value: "PAID",
			},
			{
				text: "Tidak Sah",
				value: "VOID",
			},
		],
		onFilter: (value, record) => record.status.indexOf(value) === 0,
	},
	{
		title: "Total",
		dataIndex: "total",
		key: "total",
		render: (total) =>
			Intl.NumberFormat("id-ID", { style: "currency", currency: "IDR" }).format(total),
	},
];

const StudentDetailModal = ({ studentId, isVisible, onClose }) => {
	const [student, setStudent] = React.useState(null);
	const [isLoading, setLoading] = React.useState(false);
	const [mappedInvoices, setMappedInvoices] = React.useState([]);

	React.useEffect(() => {
		if (isVisible && studentId) {
			fetchStudentDetail();
			fetchInvoiceList();
		} else {
			setStudent(null);
			setMappedInvoices([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isVisible, studentId]);

	const handleClose = () => {
		onClose();
	};

	const fetchStudentDetail = () => {
		setLoading(true);

		requestShowStudent(studentId)
			.then((response) => {
				if (response.status === 200) {
					setStudent(response.data.data);
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: response.data.message,
					});
				}
			})
			.catch((error) => {
				notification["error"]({
					message: "Kesalahan!",
					description: error.message,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const fetchInvoiceList = () => {
		setLoading(true);

		requestGetInvoiceList({
			page: 1,
			limit: 1000,
			order: "issuedAt:asc",
			keyword: "",
			filter: `student:${studentId}`,
		})
			.then((response) => {
				if (response.status === 200) {
					setMappedInvoices(
						response.data.data.map((invoice) => {
							return {
								...invoice,
								key: invoice.id,
							};
						})
					);
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: response.data.message,
					});
				}
			})
			.catch((error) => {
				notification["error"]({
					message: "Kesalahan!",
					description: error.message,
				});
			})
			.finally(() => {
				setLoading(false);
			});
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={"calc(100% - 64px)"}
				style={{
					top: 32,
				}}
				title={"Detail Siswa"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Tutup
					</Button>,
				]}>
				<Spin spinning={isLoading}>
					{student && (
						<Row gutter={{ xs: 8, sm: 8, md: 16, lg: 16 }}>
							<Col lg={16} md={24}>
								<Space
									direction="vertical"
									size="middle"
									className="layout-popup"
									style={{ width: "100%" }}>
									<Card title="Detail Siswa" size="small">
										{student && (
											<Descriptions layout="vertical" column={2}>
												<Descriptions.Item label="Nama">{student.name}</Descriptions.Item>
												<Descriptions.Item label="NISN">{student.number}</Descriptions.Item>
											</Descriptions>
										)}
									</Card>
									<Card title="Daftar Tagihan" size="small">
										<Table
											size="small"
											columns={invoiceColumns}
											dataSource={mappedInvoices}
											loading={isLoading}
											bordered
											pagination={false}
										/>
									</Card>
								</Space>
							</Col>
							<Col lg={8} md={24}>
								<Space
									direction="vertical"
									size="middle"
									className="layout-popup"
									style={{ width: "100%" }}>
									<Card title="Histori Kelas" size="small">
										<Space
											style={{
												width: "100%",
											}}
											direction="vertical"
											size="middle">
											<Steps
												size="small"
												style={{ width: "100%" }}
												progressDot
												direction="vertical"
												current={student.classrooms.length}
												type="default"
												items={student.classrooms.map((classroom) => {
													return {
														title: classroom.name,
														description: (
															<span>
																{classroom.year.name}
																<Button
																	type="dashed"
																	style={{ marginLeft: 4 }}
																	size="small"
																	onClick={() => {
																		// TODO: Show report card
																		notification.info({
																			message: "Informasi",
																			description: "Fitur masih dalam tahap pengembangan.",
																		});
																	}}>
																	Buku Rapor
																</Button>
															</span>
														),
													};
												})}
											/>
										</Space>
									</Card>
								</Space>
							</Col>
						</Row>
					)}
				</Spin>
			</Modal>
		</>
	);
};

export default StudentDetailModal;

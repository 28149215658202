import { Layout, Typography } from "antd";

const { Footer } = Layout;

function CustomFooter() {
	return (
		<Footer
			style={{
				display: "flex",
				justifyContent: "center",
				padding: "16px",
			}}>
			<Typography.Text>Smartiva ©{new Date().getFullYear()}</Typography.Text>
			<Typography.Text style={{ margin: "0 8px" }}>•</Typography.Text>
			<Typography.Text>Crafted with ❤️ by Codeiva</Typography.Text>
		</Footer>
	);
}

export default CustomFooter;

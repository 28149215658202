import {
	Button,
	Descriptions,
	Form,
	Input,
	Modal,
	notification,
	Popconfirm,
	Select,
	Table,
	Typography,
} from "antd";
import { requestPayBill } from "../../../services/admin.service";
import { useEffect, useState } from "react";
import { requestGetPaymentList } from "../../../services/user.service";
import { format } from "../../../utils/Formatter";
import dayjs from "dayjs";

export default function SchoolBillPaymentFormModal({ open, bill, onClose, onSuccess }) {
	const [form] = Form.useForm();
	const channel = Form.useWatch("channel", form);

	const [isLoading, setLoading] = useState(false);
	const [payments, setPayments] = useState([]);
	const [isChangeMode, setChangeMode] = useState(false);

	useEffect(() => {
		if (open && bill) {
			fetchPayments();
			if (bill.channel) {
				form.setFieldsValue({ channel: bill.channel });
			}
		} else {
			setPayments([]);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open]);

	const fetchPayments = async () => {
		setLoading(true);

		requestGetPaymentList(bill.total)
			.then((response) => {
				setPayments(response.data.data.filter((payment) => payment.type === "AUTOMATIC"));
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification.error({
						message: "Error!",
						description: error.response.data.message,
					});
				} else {
					notification.error({
						message: "Error!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const handleClose = () => {
		onClose();
		form.resetFields();
	};

	const handleSubmit = async () => {
		if (
			bill &&
			bill.reference &&
			bill.reference.paymentUrl &&
			!isChangeMode &&
			dayjs(bill.reference.dueAt).isAfter(dayjs())
		) {
			window.open(bill.reference.paymentUrl, "_blank");
			return;
		} else {
			form.validateFields().then(async (values) => {
				setLoading(true);

				requestPayBill(bill._id, values.channel)
					.then((response) => {
						const payment = response.data.data;
						if (!payment) {
							notification.error({
								message: "Kesalahan!",
								description: "Pembayaran gagal, silakan coba lagi.",
							});
							return;
						}

						// open payment.paymentUrl in blank
						window.open(payment.paymentUrl, "_blank");
						onSuccess();
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification.error({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						} else {
							notification.error({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						setLoading(false);
					});
			});
		}
	};

	return (
		<Modal
			maskClosable={false}
			width={512}
			title="Pembayaran Tagihan"
			open={open}
			cancelText="Batal"
			onCancel={handleClose}
			footer={[
				<Button key={1} onClick={handleClose}>
					Batal
				</Button>,
				bill && bill.status === "CREATED" && (
					<>
						{bill.reference &&
							bill.reference.paymentUrl &&
							(isChangeMode ? (
								<Button key={2} onClick={() => setChangeMode(false)}>
									Batal Ubah
								</Button>
							) : (
								<Button key={2} onClick={() => setChangeMode(true)}>
									Ubah Metode Pembayaran
								</Button>
							))}
						,
						<Popconfirm
							key={3}
							onConfirm={handleSubmit}
							title="Apakah Anda yakin ingin membayar tagihan ini?"
							okText="Ya"
							cancelText="Tidak">
							<Button loading={isLoading} type="primary">
								{"Bayar"}
							</Button>
						</Popconfirm>
					</>
				),
			]}>
			<Form form={form} layout="vertical" requiredMark="optional">
				{bill && (
					<>
						<Table
							size="small"
							bordered
							dataSource={bill.items.map((item) => ({ ...item, key: item._id }))}
							columns={[
								{
									title: "Name",
									dataIndex: "name",
									key: "name",
								},
								{
									title: "Nominal",
									dataIndex: "nominal",
									key: "nominal",
									render: (text, record) => <Typography.Text>{format(text)}</Typography.Text>,
								},
								{
									title: "Qty",
									dataIndex: "quantity",
									key: "quantity",
									render: (text, record) => <Typography.Text>{text}</Typography.Text>,
								},
								{
									title: "Jumlah",
									dataIndex: "amount",
									key: "amount",
									render: (text, record) => (
										<Typography.Text>{format(record.nominal * record.quantity)}</Typography.Text>
									),
								},
							]}
							summary={(pageData) => {
								let total = 0;
								pageData.forEach(({ nominal, quantity }) => {
									total += nominal * quantity;
								});
								return (
									<>
										<Table.Summary.Row>
											<Table.Summary.Cell colSpan={3}>Total</Table.Summary.Cell>
											<Table.Summary.Cell>
												<Typography.Text strong>{format(total)}</Typography.Text>
											</Table.Summary.Cell>
										</Table.Summary.Row>
									</>
								);
							}}
							pagination={false}
						/>
						<Form.Item
							style={{
								marginTop: 12,
							}}
							label="Metode Pembayaran"
							name="channel"
							rules={[{ required: true, message: "Please select a channel!" }]}>
							{bill && bill.status === "CREATED" ? (
								<Select
									disabled={bill.reference && bill.reference.paymentUrl && !isChangeMode}
									placeholder="Select a channel"
									allowClear
									showSearch
									optionFilterProp="children">
									{payments
										.filter((payment) => payment.code !== bill.channel)
										.map((payment) => (
											<Select.Option key={payment.code} value={payment.code}>
												{payment.name}
											</Select.Option>
										))}
								</Select>
							) : (
								<Input disabled value={bill.channel} />
							)}
						</Form.Item>
						<Descriptions bordered size="small" column={1} style={{ marginTop: 12 }}>
							<Descriptions.Item label="Subtotal Tagihan">
								<Typography.Text>{format(bill.total)}</Typography.Text>
							</Descriptions.Item>
							{channel && channel !== "" && (
								<Descriptions.Item label="Biaya Transaksi Bank">
									<Typography.Text>
										{format(payments.find((payment) => payment.code === channel)?.fee ?? 0)}
									</Typography.Text>
								</Descriptions.Item>
							)}
							<Descriptions.Item label="Total Tagihan">
								<Typography.Text>
									{format(
										bill.total +
											(channel && channel !== ""
												? payments.find((payment) => payment.code === channel)?.fee
												: 0)
									)}
								</Typography.Text>
							</Descriptions.Item>
						</Descriptions>
					</>
				)}
			</Form>
		</Modal>
	);
}

import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(relativeTime);
dayjs.extend(isSameOrAfter);

export function formatTime(input) {
	const now = dayjs();
	const date = dayjs(input);

	const diffInSeconds = now.diff(date, "second");
	const diffInMinutes = now.diff(date, "minute");
	const diffInHours = now.diff(date, "hour");
	const diffInDays = now.diff(date, "day");
	const diffInWeeks = now.diff(date, "week");

	if (diffInSeconds < 10) {
		return "baru saja";
	} else if (diffInSeconds < 60) {
		return `${diffInSeconds} detik yang lalu`;
	} else if (diffInMinutes < 60) {
		return `${diffInMinutes} menit yang lalu`;
	} else if (diffInHours < 24) {
		return `${diffInHours} jam yang lalu`;
	} else if (diffInDays < 7) {
		return `${diffInDays} hari yang lalu`;
	} else if (diffInWeeks < 4) {
		return `${diffInWeeks} minggu yang lalu`;
	} else {
		return date.format("DD/MM/YYYY HH:mm");
	}
}

/* eslint-disable react-hooks/exhaustive-deps */
import { App, Button, Form, Modal, Popconfirm, Select, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { requestEnrollStudentsToClassroom, requestGetStudentList } from "../services/admin.service";

const StudentEnrollFormModal = ({
	classroom,
	isVisible,
	isLoading,
	onLoading,
	onClose,
	onSuccess,
}) => {
	const { notification } = App.useApp();

	const [form] = Form.useForm();

	const [keyword, setKeyword] = useState("");
	const [students, setStudents] = useState([]);

	useEffect(() => {
		if (isVisible && !isLoading) {
			fetchStudentList();
		}
	}, [isVisible, keyword]);

	const fetchStudentList = () => {
		onLoading(true);
		requestGetStudentList({
			keyword: keyword,
			page: 1,
			limit: 10,
			filter: `status:INACTIVE`,
		})
			.then((response) => {
				// merge unique data
				const uniqueData = [...students, ...response.data.data];
				const uniqueStudents = [...new Map(uniqueData.map((item) => [item["_id"], item])).values()];
				const sortedStudents = uniqueStudents.sort((a, b) => {
					if (a.number < b.number) {
						return -1;
					}
					if (a.number > b.number) {
						return 1;
					}
					return 0;
				});
				setStudents(sortedStudents);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				onLoading(false);
			});
	};

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);

				requestEnrollStudentsToClassroom(classroom._id, values)
					.then((response) => {
						notification["success"]({
							message: "Good job!",
							description: response.data.message,
						});
						setStudents([]);
						form.resetFields();
						onSuccess();
					})
					.catch((error) => {
						if (error.response && error.response.data) {
							notification["error"]({
								message: "Kesalahan!",
								description: error.response.data.message,
							});
						} else {
							notification["error"]({
								message: "Kesalahan!",
								description: error.message,
							});
						}
					})
					.finally(() => {
						onLoading(false);
					});
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<>
			{classroom && (
				<Modal
					maskClosable={false}
					width={512}
					title={`Tambahkan Siswa ke Kelas ${classroom.name}`}
					open={isVisible}
					cancelText="Batal"
					onCancel={handleClose}
					footer={[
						<Button key={1} onClick={handleClose}>
							Batal
						</Button>,
						<Popconfirm
							key={2}
							onConfirm={handleSubmit}
							title="Yakin akan mendaftarkan siswa ini?"
							okText="Ya"
							cancelText="Tidak">
							<Button type="primary">{"Daftarkan"}</Button>
						</Popconfirm>,
					]}>
					<Spin spinning={isLoading}>
						<Form form={form} layout="vertical" requiredMark="optional">
							<Form.Item
								style={{ marginTop: 0, marginBottom: 0 }}
								name="studentIds"
								label="Nama"
								required
								rules={[{ required: true, message: "Pilih siswa!" }]}
								tooltip="Pilih siswa">
								<Select
									mode="multiple"
									placeholder="Pilih Siswa"
									showSearch
									optionFilterProp="children"
									onSearch={(value) => {
										setKeyword(value);
									}}
									virtual={true}
									loading={isLoading}
									filterOption={(input, option) =>
										option.children.toLowerCase().includes(input.toLowerCase())
									}
									filterSort={(optionA, optionB) =>
										optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
									}>
									{students.map((student) => (
										<Select.Option key={student._id} value={student._id}>
											{`[${student.number}] ${student.name}`}
										</Select.Option>
									))}
								</Select>
							</Form.Item>
						</Form>
					</Spin>
				</Modal>
			)}
		</>
	);
};

export default StudentEnrollFormModal;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import { MoreOutlined } from "@ant-design/icons";
import {
	App,
	Button,
	Card,
	Col,
	Divider,
	Dropdown,
	Row,
	Space,
	Table,
	Tag,
	Typography,
} from "antd";
import { useEffect, useState } from "react";
import { requestGetPaymentTransactionList } from "../../../services/admin.service";
import TransactionFormModal from "./components/TransactionFormModal";
import {
	HiArrowDown,
	HiArrowDownCircle,
	HiArrowUp,
	HiArrowUpCircle,
	HiOutlineEye,
} from "react-icons/hi2";
import dayjs from "dayjs";
import { format } from "../../../utils/Formatter";
import SearchInput from "../../../components/SearchInput";
import DateRangeFilter from "../../../components/DateRangeFilter";
import TransactionDetailModal from "./components/TransactionDetailModal";
import { useSearchParams } from "react-router-dom";

const columns = [
	{
		title: "ID Transaksi",
		dataIndex: "id",
		key: "id",
		render: (text, record) => {
			return (
				<span
					style={{
						fontWeight: "bold",
					}}>
					{text}{" "}
					{record.status === "COMPLETED" ? (
						<Tag color="success">Berhasil</Tag>
					) : record.status === "CREATED" ? (
						<Tag color="warning">Dibuat</Tag>
					) : record.status === "SUBMITTED" ? (
						<Tag color="warning">Pengecekan</Tag>
					) : record.status === "FAILED" ? (
						<Tag color="error">Gagal</Tag>
					) : (
						<Tag color="default">Tidak Sah</Tag>
					)}
				</span>
			);
		},
	},
	{
		title: "Date",
		dataIndex: "date",
		key: "date",
		sorter: true,
	},
	{
		title: "Kategori",
		dataIndex: "category",
		key: "category",
		render: (text, record) => {
			return (
				<span>
					{record.type === "INCOME" ? (
						<Tag color="success">
							<HiArrowDown />
						</Tag>
					) : (
						<Tag color="error">
							<HiArrowUp />
						</Tag>
					)}
					{text}
				</span>
			);
		},
		filters: [
			{
				text: "Biaya Pendidikan",
				value: "TUITION",
			},
			{
				text: "Gaji Guru",
				value: "SALARY",
			},
			{
				text: "Biaya Operasional",
				value: "OPERATIONAL",
			},
			{
				text: "Biaya Lainnya",
				value: "OTHERS",
			},
		],
	},
	{
		title: "Nominal",
		dataIndex: "nominal",
		key: "nominal",
		render: (text, record) => {
			return format(text);
		},
	},
	{
		title: "Channel",
		dataIndex: "channel",
		key: "channel",
		render: (text, record) => {
			return <Tag color="default">{text}</Tag>;
		},
		filters: [
			{
				text: "Tabungan",
				value: "POCKET",
			},
			{
				text: "BCA",
				value: "BCA",
			},
			{
				text: "Mandiri",
				value: "MANDIRI",
			},
			{
				text: "Maybank",
				value: "MAYBANK",
			},
			{
				text: "BNI",
				value: "BNI",
			},
			{
				text: "CIMB",
				value: "CIMB",
			},
			{
				text: "Permata",
				value: "PERMATA",
			},
			{
				text: "ATM",
				value: "ATM",
			},
			{
				text: "Artha",
				value: "ARTHA",
			},
			{
				text: "BNC",
				value: "BNC",
			},
			{
				text: "BRI",
				value: "BRI",
			},
		],
	},
	{
		title: "Aksi",
		dataIndex: "actions",
		key: "actions",
		align: "right",
	},
];

const PaymentTransactionPage = () => {
	const [searchParams, setSearchParams] = useSearchParams();
	const { message, notification, modal } = App.useApp();
	// const keyword = searchParams.get("keyword");
	// const filter = searchParams.get("filter");

	// SEARCH, FILTER, PAGINATION
	const [keyword, setKeyword] = useState("");
	const [filter, setFilter] = useState({
		startedAt: dayjs().startOf("month").format("YYYY-MM-DD"),
		endedAt: dayjs().endOf("month").format("YYYY-MM-DD"),
		channel: "POCKET|BCA|MANDIRI|MAYBANK|BNI|CIMB|PERMATA|ATM|ARTHA|BNC|BRI",
	});
	const [sorter, setSorter] = useState({
		_id: "desc",
	});
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
	const [limit, setLimit] = useState(10);

	const handleSearch = (value) => {
		setKeyword(value.trim());
	};

	const handlePaginate = (pagination, filters, sorter) => {
		setPage(pagination.current);
		setLimit(pagination.pageSize);

		// UPDATE FILTER
		if (filters) {
			const additionalFilter = {};
			Object.keys(filters).forEach((key) => {
				if (filters[key]) {
					additionalFilter[key] = filters[key].join("|");
				} else if (key === "channel") {
					additionalFilter[key] = "POCKET|BCA|MANDIRI|MAYBANK|BNI|CIMB|PERMATA|ATM|ARTHA|BNC|BRI";
				} else {
					additionalFilter[key] = "";
				}
			});

			setFilter({
				...filter,
				...additionalFilter,
			});
		} else {
			const tempFilter = {};
			// only allow startedAt and endedAt
			Object.keys(filter).forEach((key) => {
				if (key === "startedAt" || key === "endedAt") {
					tempFilter[key] = filter[key];
				}
			});

			setFilter(tempFilter);
		}

		// UPDATE SORTER
		if (sorter && sorter.field) {
			setSorter({
				[sorter.field]: sorter.order === "ascend" ? "asc" : "desc",
			});
		} else {
			setSorter({
				_id: "desc",
			});
		}
	};
	// SEARCH, FILTER, PAGINATION

	const [transactions, setTransactions] = useState([]);
	const [summary, setSummary] = useState({
		income: 0,
		expense: 0,
	});
	const [mappedTransactions, setMappedTransactions] = useState([]);
	const [isLoading, setLoading] = useState(false);

	const [transaction, setTransaction] = useState(null);
	const [isTransactionFormVisible, setTransactionFormVisible] = useState(false);
	const [isTransactionDetailVisible, setTransactionDetailVisible] = useState(false);

	useEffect(() => {
		fetchTransactionList();
	}, []);

	useEffect(() => {
		setSearchParams({ keyword: keyword, page: page, limit: limit });

		fetchTransactionList();
	}, [keyword, filter, page, limit, sorter]);

	useEffect(() => {
		if (searchParams.has("keyword")) {
			setKeyword(searchParams.get("keyword"));
		}
		if (searchParams.has("page")) {
			setPage(parseInt(searchParams.get("page")));
		}
		if (searchParams.has("limit")) {
			setLimit(parseInt(searchParams.get("limit")));
		}
	}, []);

	const fetchTransactionList = () => {
		setLoading(true);

		let tempFilter = "";
		if (filter) {
			tempFilter = Object.keys(filter)
				.map((key) => `${key}:${filter[key]}`)
				.filter(
					(f) => f.split(":")[1] !== "" && f.split(":")[1] !== undefined && f.split(":")[1] !== null
				)
				.join(",");
		}

		let tempSorter = "";
		if (sorter) {
			tempSorter = Object.keys(sorter)
				.map((key) => `${key}:${sorter[key]}`)
				.filter(
					(s) => s.split(":")[1] !== "" && s.split(":")[1] !== undefined && s.split(":")[1] !== null
				)
				.join(",");
		}

		requestGetPaymentTransactionList({
			page: page,
			limit: limit,
			order: tempSorter ?? "_id:desc",
			keyword: keyword ?? "",
			filter: tempFilter ?? "",
		})
			.then((response) => {
				setTransactions(response.data.data);
				setTotal(response.data.pagination.total);
				setSummary(response.data.summary);
				updateMappedTransaction(response.data.data);
			})
			.catch((error) => {
				if (error.response && error.response.data) {
					notification["error"]({
						message: "Kesalahan!",
						description: error.response.data.message,
					});
				} else {
					notification["error"]({
						message: "Kesalahan!",
						description: error.message,
					});
				}
			})
			.finally(() => {
				setLoading(false);
			});
	};

	const updateMappedTransaction = (data) => {
		setMappedTransactions(
			data.map((d) => {
				const otherButtons = [
					{
						key: "show",
						label: "Lihat",
						icon: <HiOutlineEye />,
					},
				];

				return {
					key: d._id,
					id: d._id,
					status: d.status,
					date: dayjs(d.date).format("DD MMMM YYYY HH:mm"),
					type: d.type,
					category: d.category,
					nominal: d.nominal,
					total: d.nominal + d.fees.map((f) => f.nominal).reduce((a, b) => a + b, 0),
					channel: d.channel,
					actions: (
						<Space>
							<Dropdown
								trigger={["click"]}
								menu={{
									onClick: (e) => {
										if (e.key === "show") {
											handleShowButton(d);
										}
									},
									items: otherButtons,
								}}
								placement="bottomRight"
								arrow>
								<Button type="default" shape="circle" size="small" icon={<MoreOutlined />} />
							</Dropdown>
						</Space>
					),
				};
			})
		);
	};

	const handleAddButton = () => {
		setTransactionFormVisible(true);
	};

	const handleShowButton = (transaction) => {
		setTransaction(transaction);
		setTransactionDetailVisible(true);
	};

	const handleOnCloseTransactionForm = () => {
		setTransaction(null);
		setTransactionFormVisible(false);
	};

	const handleOnSuccessTransactionForm = () => {
		setTransaction(null);
		setTransactionFormVisible(false);
		fetchTransactionList();
	};

	const handleOnCloseTransactionDetail = () => {
		setTransactionDetailVisible(false);
		setTransaction(null);
	};

	return (
		<>
			<TransactionFormModal
				isLoading={isLoading}
				isVisible={isTransactionFormVisible}
				onLoading={(v) => setLoading(v)}
				transaction={transaction}
				onClose={handleOnCloseTransactionForm}
				onSuccess={handleOnSuccessTransactionForm}
			/>
			<TransactionDetailModal
				isVisible={isTransactionDetailVisible}
				transactionId={transaction?._id}
				onClose={handleOnCloseTransactionDetail}
			/>
			<Card
				bordered={false}
				title="Daftar Transaksi"
				extra={
					<Space>
						<SearchInput
							placeholder="Masukkan ID"
							onSearch={handleSearch}
							defaultValue={keyword}
							isLoading={isLoading}
						/>
						<DateRangeFilter
							onOk={(start, end) => {
								setFilter({
									startedAt: start,
									endedAt: end,
								});
							}}
							startedAt={filter.startedAt}
							endedAt={filter.endedAt}
						/>
						{/* <Button icon={<PlusCircleFilled />} type="primary" onClick={handleAddButton}>
										<span className="ant-btn-text">Tambah</span>
									</Button> */}
					</Space>
				}>
				<Space
					direction="vertical"
					style={{
						width: "100%",
					}}>
					<Row gutter={[16, 16]}>
						<Col xs={24} xl={12}>
							<Card>
								<Space direction="horizontal" style={{ width: "100%" }}>
									<div
										className="text-success"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<HiArrowDownCircle size={48} style={{ color: "limegreen" }} />
									</div>
									<Col gutter={0}>
										<Typography.Title level={5} style={{ margin: 0 }}>
											Total Pemasukan
										</Typography.Title>
										<Typography.Title level={2} style={{ margin: 0 }} className="text-success">
											{format(summary.income)}
										</Typography.Title>
									</Col>
								</Space>
							</Card>
						</Col>
						<Col xs={24} xl={12}>
							<Card>
								<Space direction="horizontal" style={{ width: "100%" }}>
									<div
										className="text-danger"
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
										}}>
										<HiArrowUpCircle size={48} style={{ color: "red" }} />
									</div>
									<Col gutter={0}>
										<Typography.Title level={5} style={{ margin: 0 }}>
											Total Pengeluaran
										</Typography.Title>
										<Typography.Title level={2} style={{ margin: 0 }} className="text-danger">
											{format(summary.expense)}
										</Typography.Title>
									</Col>
								</Space>
							</Card>
						</Col>
					</Row>
				</Space>
				<Divider style={{ margin: "0" }} />
				<div>
					<Table
						style={{
							marginTop: 16,
						}}
						bordered
						columns={columns}
						loading={isLoading}
						dataSource={mappedTransactions}
						pagination={{
							current: page,
							total: total,
							position: "bottomRight",
							pageSizeOptions: ["10", "20", "50", "100"],
							showSizeChanger: true,
							locale: { items_per_page: "item/hal" },
						}}
						onChange={handlePaginate}
					/>
				</div>
			</Card>
		</>
	);
};

export default PaymentTransactionPage;

import React, { useEffect } from "react";
import AppContext from "../store/app.context";
import base64 from "crypto-js/enc-base64";
import hmac from "crypto-js/hmac-sha256";

async function initTawkToChat(user) {
	const hash = hmac(user.email, "fe77e9b44ab7355bbc5596d0eababdd277212383").toString(base64);
	const Tawk_API = window.Tawk_API || {};
	Tawk_API.visitor = {
		name: user.name,
		email: user.email,
		hash: hash,
	};
	const Tawk_LoadStart = new Date();

	const script = document.createElement("script");
	script.async = true;
	script.src = "https://embed.tawk.to/670e59fe2480f5b4f58dad96/1ia80v2u7";
	script.charset = "UTF-8";
	script.setAttribute("crossorigin", "*");

	const firstScriptTag = document.getElementsByTagName("script")[0];
	firstScriptTag.parentNode.insertBefore(script, firstScriptTag);

	return () => {
		document.head.removeChild(script); // Clean up on unmount
	};
}

const TawkToChat = () => {
	const context = React.useContext(AppContext);

	useEffect(() => {
		if (context.user && process.env.NODE_ENV === "production") {
			initTawkToChat(context.user);
		}
	}, [context.user]); // Run only once when the component mounts

	return null; // No visual UI
};

export default TawkToChat;

import { Scanner } from "@yudiel/react-qr-scanner";
import { Button, Modal, Space } from "antd";
import { useEffect, useState } from "react";

export default function PocketScannerModal({ isVisible, onScan, onCancel }) {
	const [isScanning, setScanning] = useState(false);

	useEffect(() => {
		if (isVisible) {
			setScanning(true);
		} else {
			setScanning(false);
		}
	}, [isVisible]);

	return (
		<Modal
			maskClosable={false}
			title="Scan Kartu"
			open={isVisible}
			onCancel={onCancel}
			footer={[
				<Button key="back" onClick={onCancel}>
					Batal
				</Button>,
			]}>
			<Space>
				{isScanning && (
					<Scanner
						onScan={(result) => {
							onScan(result);
						}}
					/>
				)}
			</Space>
		</Modal>
	);
}

/* eslint-disable react-hooks/exhaustive-deps */
import {
	Button,
	DatePicker,
	Form,
	Input,
	Modal,
	Popconfirm,
	Select,
	Spin,
	message,
	notification,
} from "antd";
import React, { useEffect } from "react";
import { addStudent, editStudent } from "../../../../services/admin.service";
import dayjs from "dayjs";

const StudentFormModal = ({
	classrooms,
	selectedYear,
	student,
	isVisible,
	isLoading,
	onLoading,
	onClose,
	onSuccess,
}) => {
	const [items, setItems] = React.useState([]);

	const [form] = Form.useForm();

	useEffect(() => {
		if (student) {
			form.setFieldsValue({
				number: student.number,
				name: student.name,
				birthPlace: student.birthPlace,
				birthDate: dayjs(student.birthDate, "YYYY-MM-DD"),
				address: student.address,
				gender: student.gender,
			});

			setItems(items);
		}
	}, [student]);

	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => {
				onLoading(true);
				if (student) {
					values.id = student._id;
					editStudent(values)
						.then((response) => {
							message.success("Data siswa berhasil diubah");
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification.error({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification.error({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				} else {
					addStudent(values)
						.then((response) => {
							notification["success"]({
								message: "Good job!",
								description: response.data.message,
							});
							form.resetFields();
							onSuccess();
						})
						.catch((error) => {
							if (error.response && error.response.data) {
								notification["error"]({
									message: "Kesalahan!",
									description: error.response.data.message,
								});
							} else {
								notification["error"]({
									message: "Kesalahan!",
									description: error.message,
								});
							}
						})
						.finally(() => {
							onLoading(false);
						});
				}
			})
			.catch((error) => {});
	};

	const handleClose = () => {
		form.resetFields();
		onClose();
	};

	return (
		<>
			<Modal
				maskClosable={false}
				width={512}
				title={student === null || student === undefined ? "Tambah Siswa" : "Ubah Siswa"}
				open={isVisible}
				cancelText="Batal"
				onCancel={handleClose}
				footer={[
					<Button key={1} onClick={handleClose}>
						Batal
					</Button>,
					<Popconfirm
						key={2}
						onConfirm={handleSubmit}
						title="Yakin akan menyimpan data siswa?"
						okText="Ya"
						cancelText="Tidak">
						<Button type="primary">{student ? "Simpan" : "Tambah"}</Button>
					</Popconfirm>,
				]}>
				<Spin spinning={isLoading}>
					<Form form={form} layout="vertical" requiredMark="optional">
						<Form.Item
							style={{ marginTop: 0, marginBottom: 0 }}
							name="number"
							label="NISN"
							required
							rules={[
								{
									required: true,
									message: "Masukkan NISN",
								},
							]}
							tooltip="Masukkan NISN">
							<Input style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="name"
							label="Nama"
							required
							rules={[
								{
									required: true,
									message: "Masukkan nama siswa",
								},
							]}
							tooltip="Masukkan nama siswa">
							<Input style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="birthPlace"
							label="Tempat Lahir"
							tooltip="Masukkan tempat lahir siswa">
							<Input style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="birthDate"
							label="Tanggal Lahir"
							tooltip="Masukkan tanggal lahir siswa">
							<DatePicker style={{ width: "100%" }} />
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="gender"
							label="Jenis Kelamin"
							required
							rules={[
								{
									required: true,
									message: "Pilih jenis kelamin siswa",
								},
							]}
							tooltip="Pilih jenis kelamin siswa">
							<Select style={{ width: "100%" }}>
								<Select.Option value="MALE">Laki-laki</Select.Option>
								<Select.Option value="FEMALE">Perempuan</Select.Option>
							</Select>
						</Form.Item>
						<Form.Item
							style={{ marginTop: 12, marginBottom: 0 }}
							name="address"
							label="Alamat"
							tooltip="Masukkan alamat siswa">
							<Input.TextArea style={{ width: "100%" }} />
						</Form.Item>
					</Form>
				</Spin>
			</Modal>
		</>
	);
};

export default StudentFormModal;
